
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,300,400,500,600,700,800,900|Source+Sans+Pro:200,300,400,600,700,900');

body {
    line-height: 28px;
    font-family: Source Sans Pro!important;
    animation-name: none;
}
html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Montserrat;
}

a,
a:hover,
a:focus {
    text-decoration: none !important;
    outline: none;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus,
.form-control:focus {
    border-color: #900C3F;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 1px #900C3F;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 1px #900C3F;
    outline: 0 none;
}

ul {
    list-style-image: url('https://demo1.aecglobal.co.in/Content/zutech/list-style.png');
}

ul li {
    margin-bottom: 10px;
}
.single_item_content {
    background: #900C3F;
}

.dropdown-item.active,
.dropdown-item:active,
.excerpt-date,
.content-date,
.post-navigation:hover,
.widget.widget_tags a:hover,
.excerpt-date,
.zutech-btn a,
.zutech-btn input,
.portfolio-filter li.select-cat,
.gradient-bg,
.service-item:hover,
.feature-item:hover .feature-item-icon,
.banner .bnr-social ul li a:hover,
.banner .bnr-btn ul li a:hover {
    background-image: linear-gradient(-36deg, #900C3F 0%, #900C3F 100%);
}

.widget ul li a:hover,
.widget ul li:hover,
.widget.widget_recent_post ul li a:hover,
.excerpt-item ul li a,
.feature-item-icon i,
.bnr-counter ul li span,
.service-item i{
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: linear-gradient(-36deg, #900C3F 0%, #900C3F 100%);
}

.bnr-counter ul li:nth-child(1) span,
.bnr-counter ul li:nth-child(3) span {
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: linear-gradient(0deg, rgb(255, 182, 147) 0%, rgb(255, 167, 195) 100%);
}


.banner p span,
.section-title > span,
.section-title h1 span,
.section-title > span {
        color: #900C3F;
   
}

.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}


.space {
    overflow: hidden;
    display: block;
}

.img-shadow {
    -webkit-box-shadow: 0px 30px 64px 16px rgba(129, 116, 254, 0.15);
    box-shadow: 0px 30px 64px 16px rgba(129, 116, 254, 0.15);
}

.awards {
    margin-right: 50px;
    margin-top: 50px;
}

.awards i {
    font-size: 50px;
    line-height: 50px;
}

.awards p {
    font-size: 20px;
    color: #260634;
    font-weight: 600;
    margin-top: 10px;
}




.dropdown-menu {
    border-radius: 0;
    padding: 0;
}



.section-title h1 {
    font-weight: bold;
    font-size: 35px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.section-title > span {
    font-weight: bold;
    text-transform: uppercase;
}

.section-title.white {
    color: #fff;
}

.section-title.white span {
    color: #fff;
}


.banner {
    position: relative;
    height: 600px!important;
}

.bnr-left-sape {
    position: absolute;
    left: -50%;
}

.pic-right-bottom {
    position: absolute;
    right: -11%;
    bottom: -10%;
    z-index: -1;
}



.banner .bnr-shape-img-2 {
    position: absolute;
    width: 48%;
    height: 115%;
    background: url(./images/bnr-shape-2.png);
    top: 0;
    right: 0;
    background-repeat: no-repeat;
    background-size: cover;
}

.banner h1 {
    font-size: 60px;
        color: #900C3F;
    text-shadow: 1px 2px #272926;
    margin-bottom: 20px;
    font-weight: bold;
}

.banner p {
    color: #900C3F;
    display: block;
    font-size: 24px;
}

.banner .bnr-social {
    margin-bottom: 25px;
}

.banner .bnr-social ul li a {
    padding: 8px 12px;
    border-radius: 50%;
   border: 1px solid #666666;
    color: #666666;
    margin-right: 10px;
    display: inline-block
}

.banner .bnr-social ul li a:hover {
    color: #fff;
}

.banner .bnr-btn {
    margin-top: 50px;
}

.banner .bnr-btn ul li a:hover {
    color: #fff;
}

.banner .bnr-btn ul {
    margin-top: 30px;
}

.banner .bnr-btn ul li a {
    padding: 12px 30px;
    border: 1px solid #000;
    color: #900C3F;
    border-radius: 3px;
    display: inline-block;
    margin-right: 15px;
}

.banner .bnr-btn ul li a:hover {
    color: #fff;
}

.banner .bnr-counter {
    margin-top: 10%;
}

.banner .bnr-counter ul li {
    text-align: center;
    padding: 0;
    margin: 0;
    position: relative;
}

.banner .bnr-counter ul li:after {
    position: absolute;
    content: '';
    right: -30%;
    top: 0;
    height: 100%;
    width: 1px;
    background: #e5e5e5;
}

.banner .bnr-counter ul li:last-child::after {
    display: none;
}

.banner .bnr-counter ul li:nth-child(2) {
    margin: 0 80px;
}

.banner .bnr-counter ul li span {
    font-size: 35px;
    font-weight: bold;
}

.banner .bnr-shape-bg {
    position: relative;
}

.banner-2 {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 900px;
    padding-top: 100px;
    position: relative;
}

.banner-2 .bnr-shape-img-3 {
    position: absolute;
    top: 0;
    right: 0;
}

.banner-2 .bnr-social {
    margin-top: 130px;
}

.banner-2 h1 {
    font-size: 56px;
    color: #900C3F;
    margin-bottom: 20px;
    font-weight: bold;
}

.banner-2 p {
    color: #900C3F;
    display: block;
    font-size: 24px;
}

.banner-2 .bnr-social {
    margin-bottom: 25px;
}

.banner-2 .bnr-social ul li a {
    padding: 8px 12px;
    border-radius: 50%;
    border: 1px solid #666666;
    color: #666666;
    margin-right: 10px;
    display: inline-block
}

.banner-2 .bnr-social ul li a:hover {
    color: #fff;
}

.banner-2 .bnr-btn {
    margin-top: 50px;
}

.banner-2 .bnr-btn ul li a:hover {
    color: #fff;
}

.banner-2 .bnr-btn ul {
    margin-top: 30px;
}

.banner-2 .bnr-btn ul li a {
    padding: 12px 30px;
    border: 1px solid #000;
    color: #666666;
    border-radius: 3px;
    display: inline-block;
    margin-right: 15px;
}

.banner-2 .bnr-btn ul li a:hover {
    color: #fff;
}

.banner-2 .bnr-shape-img {
    position: absolute;
    width: 48%;
    height: 100%;
    top: 0;
    right: 0;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
}

.banner .bnr-shape-2 {
    width: 48%;
    height: 100%;
    top: 0;
    right: 0;
    background: url("https://demo1.aecglobal.co.in/Content/zutech/photography-vector.png");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
}

.breadcrumb-bnr {
    background: rgba(0, 0, 0, 0.88);
    height: 450px;
    overflow: hidden;
    margin-bottom: 130px;
    position: relative;
}

.breadcrumb-bnr img {
    width: 100%;
}

.breadcrumb-content {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    color: #fff;
    top: 0;
    padding: 10% 0;
    background-image: -webkit-linear-gradient(126deg, rgba(255, 182, 147, 0.8) 0%, rgba(255, 167, 195, 0.8) 100%);
    background-image: -o-linear-gradient(126deg, rgba(255, 182, 147, 0.8) 0%, rgba(255, 167, 195, 0.8) 100%);
    background-image: linear-gradient(-36deg, rgba(0, 0, 0, 0.8) 0%, rgba(255, 167, 195, 0.8) 100%);;
}

.breadcrumb-content h1 {
    font-weight: bold;
}

.breadcrumb-content ul li a {
    color: #fff
}

.banner-3 {
    background: url("https://demo1.aecglobal.co.in/Content/zutech/banner-shape.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    color: #fff;
    height: 900px;
    position: relative;
}

.banner-3-content {
    padding-top: 230px;
}

.banner-3 span {
    text-transform: uppercase;
    margin-top: 100px;
    font-size: 20px;
}

.banner-3 h1 {
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 56px;
    line-height: 75px;
}

.banner-3 a {
    color: #fff;
    padding: 10px 30px;
    display: inline-block;
    border-radius: 3px;
    margin-top: 30px;
}

.banner-3 p {
    font-size: 18px;
}

.banner-shape-animate img {
    position: absolute;
}

.banner-shape-animate img:nth-child(1) {
    right: 10%;
    top: 45%;
}

.banner-shape-animate img:nth-child(2) {
    right: 8%;
    top: 58%;
}

.banner-shape-animate img:nth-child(3) {
    right: 9%;
    top: 47%;
    z-index: 5;
}

.banner-shape-animate img:nth-child(4) {
    right: 12%;
    top: 39%;
    z-index: 4;
}

.banner-shape-animate img:nth-child(5) {
    right: 15%;
    top: 31%;
    z-index: 3;
}

.banner-shape-animate img:nth-child(6) {
    right: 18%;
    top: 24%;
}

.banner-shape-animate img:nth-child(7) {
    right: 27%;
    top: 15%;
}
/***************************
        Banner 4
****************************/
.banner-4 {
    background: url(https://demo1.aecglobal.co.in/Content/zutech/banner-4.png);
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    color: #fff;
    height: 900px;
    position: relative;
}
.banner-4 .banner4-image {
    right: 10%;
    position: absolute;
    top: 25%;
}
.banner-4 h1{
    font-size: 55px;
    color: #fff;
    margin-bottom: 20px;
    font-weight: 600;
}
.banner-4 .bnr-btn ul li {
    padding: 12px 30px;
    border: 1px solid #fff;
    border-radius: 3px;
    display: inline-block;
    margin-right: 15px;
    color:#fff;
}
.banner-4 .bnr-btn ul li a{
    color:#fff;
}
.banner-4 .bnr-btn{
    margin-top: 50px;
}
.banner-4 .bnr-btn .list-inline-item:not(:last-child) {
    border:1px solid #66baa7;
    background:#66baa7;
}
.banner-4 .bnr-btn .list-inline-item:hover{
    border:1px solid #66baa7;
    background:#66baa7;
}
.banner4-image .banner4_vicon a img {
    position: absolute;
    top: 40%;
    right: 47%;
}
.banner4 h1{
    font-weight: 400;
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 35px;
    color: #341242;
}
.banner4{
    width: 65%;
    margin: auto;
}
.banner4_service_item {
    padding: 10px;
    margin-bottom: 15px;
}
.banner4_service_item h5 {
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 400;
    color: #341242;
}
.banner4_service_item i {
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 64px;
    display: block;
    line-height: 80px;
}
.home4_list{
        list-style-image: url(https://demo1.aecglobal.co.in/Content/zutech/home4_list_img.png);
}
.popup-youtube img{
    margin-right:20px;
}
.popup-youtube{
        font-size: 16px;
    color: #341242;
}
@media (max-width: 1024px) {
    .banner-4 .banner4-image {
        display: none;
    }
}

/*
==============================================
floating
==============================================
*/

.floating {
    animation-name: floating;
    -webkit-animation-name: floating;

    animation-duration: 5.5s;
    -webkit-animation-duration: 5.5s;

    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

@keyframes floating {
    0% {
        transform: translateY(0%);
    }

    50% {
        transform: translateY(5%);
    }

    100% {
        transform: translateY(0%);
    }
}

@-webkit-keyframes floating {
    0% {
        -webkit-transform: translateY(0%);
    }

    50% {
        -webkit-transform: translateY(5%);
    }

    100% {
        -webkit-transform: translateY(0%);
    }
}


/***************************
        Services
****************************/
.service-item {
    -webkit-box-shadow: 0px 0px 56px 14px rgba(186, 179, 226, 0.08);
    box-shadow: 0px 0px 56px 14px rgba(186, 179, 226, 0.08);
    border-radius: 10px;
    padding: 30px;
    text-align: center;
}

.service-item i {
    margin-top: 15px;
    margin-bottom: 40px;
    font-size: 80px;
    display: block;
    line-height: 80px;
}

.service-item h5 {
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 600;
}

.service-item:hover,
.service-item:hover i {
    color: #fff;
}

.service-bg {
    background: #fff;
    margin-top: -260px;
    padding: 40px 0;
    -webkit-box-shadow: 0px 35px 64px 16px rgba(129, 116, 254, 0.05);
    box-shadow: 0px 35px 64px 16px rgba(129, 116, 254, 0.05);
}

.service-item-2 {
    padding: 25px 50px;
}

.service-item-2 i {
    font-size: 50px;
    line-height: 50px;
}

.service-item-2 h5 {
    margin-top: 20px;
    color: #341242;
    font-size: 22px;
}

/***************************
        Counter
****************************/

.counter-bg {
    background: url("https://demo1.aecglobal.co.in/Content/zutech/curve-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 70px 0 370px;
}

.counter-item {
    position: relative;
    color: #fff;
    text-align: center;
}

.counter-item:after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background: #900C3F;
}

.row .col-sm-3:last-child .counter-item:after {
    display: none;
}

.counter-item span {
    font-weight: bold;
    font-size: 35px;
}

.counter-item p {
    font-size: 24px;
}

/***************************
        Button
****************************/
.zutech-btn a,
.zutech-btn input {
    color: #fff!important;
    padding: 10px 30px;
    border-radius: 3px;
    display: inline-block;
    font-weight: 600;
}

/***************************
    Progress Circle
****************************/
.progress-title {
    color: #fff;
}

.progress-item {
    text-align: center;
}

.counter-sec {
    height: 475px;
}


.counter-sec .progress-item {
    width: 150px;
    float: left;
}

.counter-sec .progress-item:nth-child(1) {
    padding-top: 100px;
}

.counter-sec .progress-item:nth-child(2) {
    padding-top: 220px;
}

.counter-sec .progress-item:nth-child(3) {
    padding-top: 80px;
}

.counter-sec .progress-item:nth-child(4) {
    padding-top: 220px;
}

/***************************
            Feature item
****************************/
.features-bg {
    background: url(https://demo1.aecglobal.co.in/Content/zutech/shape-bg1.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    position: relative;
}

.feature-item {
    margin-bottom: 10px;
    overflow: hidden;
}

.feature-item-icon {
    float: left;
    width: 70px;
    height: 70px;
    border: 1px solid #900C3F;
    border-radius: 50%;
    text-align: center;
    margin-right: 15px;
    padding-top: 18px;

}

.feature-item-icon i {
    font-size: 40px;
}

.feature-item-content h5 {
    padding-top: 0;
    margin-top: 0;
    display: inline-block;
}

.feature-item:hover i {
    color: #fff;
}

.feature-item:hover .feature-item-icon {
    border: 1px solid #fff;
}


.circle-img-animate {
    position: relative;
}


.photo-anim .animate-obj {
    right: 25%;
}

.animate-obj {
    position: absolute;
    top: -3%;
    right: 40%;
    list-style: none;
    -webkit-animation-name: spin;
    animation-name: spin;
    -webkit-animation-duration: 25000ms;
    animation-duration: 25000ms;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

.animate-obj li {
    margin: 20px;
    position: relative;
}

.animate-obj li img {
    -webkit-animation-name: spin;
    animation-name: spin;
    -webkit-animation-duration: 5000ms;
    animation-duration: 5000ms;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

.animate-obj li:nth-child(1) {
    right: 0;
}

.animate-obj li:nth-child(2) {
    right: 200%;
}

.animate-obj li:nth-child(3) {
    right: 330%;
}

.animate-obj li:nth-child(4) {
    right: 395%;
}

.animate-obj li:nth-child(5) {
    left: 338%;
}

.animate-obj li:nth-child(6) {
    left: 393%;
}

.animate-obj li:nth-child(7) {
    right: 397%;
}

.animate-obj li:nth-child(8) {
    left: 287%;
}

.animate-obj li:nth-child(9) {
    right: 360%;
}

.animate-obj li:nth-child(10) {
    right: 248%;
}

.animate-obj li:nth-child(11) {
    left: 131%;
}

.animate-obj li:nth-child(12) {
    right: 0;
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/***************************
        Blog
****************************/
.excerpt-item,
.content-item {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: #fff;
    border-radius: 6px;
    -webkit-box-shadow: 0px 0px 56px 14px rgba(186, 179, 226, 0.08);
    box-shadow: 0px 0px 56px 14px rgba(186, 179, 226, 0.08);
    margin-bottom: 30px;
}

.excerpt-item:last-child {
    margin-bottom: 0;
}

.excerpt-item h3,
.content-item h3 {
    color: #900C3F;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 25px;
}

.excerpt-item ul li,
.content-item ul li {
    color: #666666;
    margin-right: 25px;
}

.excerpt-content,
.content-content {
    padding: 40px 40px 15px;
}

.excerpt-img,
.content-img {
    position: relative;
}

.excerpt-date,
.content-date {
    position: absolute;
    right: 0;
    bottom: 0;
    border-top-left-radius: 5px;
    color: #fff;
    padding: 10px;
    width: 110px;
}

.excerpt-date h3,
.content-date h3 {
    font-weight: bold;
    color: #fff;
    margin-bottom: 0;
}

.relative-post {
    -webkit-box-shadow: 0px 0px 56px 14px rgba(186, 179, 226, 0.08);
    box-shadow: 0px 0px 56px 14px rgba(186, 179, 226, 0.08);
}

.relative-posts h4 {
    color: #900C3F;
    margin: 40px 0 30px;
    font-weight: 600;
}

.relative-post h6 {
    padding: 20px 15px;
    font-size: 16px;
}

.relative-post a {
    color: #900C3F;
}

.post-commets {
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 30px;
    margin-bottom: 45px;
}

.post-commets h4 {
    color: #341242;
    margin: 40px 0 30px;
    font-weight: 600;
}

.post-commets .media-body h5 {
    color: #341242;
    font-size: 20px;
    margin-bottom: 0;
    font-weight: 600;
}

.post-commets .media-body span {
    color: #999999;
    font-size: 14px;
}

.post-commets .media-body p {
    color: #999999;
}

.comment-form h4 {
    color: #341242;
    font-weight: 600;
}

.comment-form p {
    color: #666;
}

.comment-form input[type='email'],
.comment-form input[type='text'],
.comment-form input[type='number'] {
    height: 50px;
    margin-bottom: 30px;
    color: #6346fe;
}

.comment-form textarea {
    height: 150px;
    margin-bottom: 30px;
    color: #900C3F;
}

.comment-form input[type='submit'] {
    border: none;
    cursor: pointer;
}


.blog-item-shape img {
    width: 100%;
}

.blog-item-shape {
    background: #fff;
    border-radius: 6px;
    -webkit-box-shadow: 0px 0px 56px 14px rgba(186, 179, 226, 0.08);
    box-shadow: 0px 0px 56px 14px rgba(186, 179, 226, 0.08);
}

.blog-item-shape-content {
    padding: 25px;
    padding-bottom: 10px;
}

.blog-item-shape:hover .blog-item-shape-content h5,
.blog-item-shape:hover .blog-item-shape-content ul,
.blog-item-shape:hover a {
    color: #fff;
}

.blog-item-shape:hover span {
    color: #900C3F;
}

.blog-item-shape-img {
    position: relative;
    text-align: center;
}

.date {
    position: absolute;
    bottom: 6%;
    right: 0;
    left: 0;
    background: #ffffff;
    width: 80px;
    margin: 0 auto;
    border-radius: 5px;
    padding: 5px 10px;
    font-weight: bold
}

.blog-item-shape-content h5 {
    color: #900C3F;
    font-size: 20px;
    font-weight: 600;
}

.blog-item-shape-content ul {
    margin-bottom: 0;
}

.blog-item-shape-content ul,
.blog-item-shape-content a {
    color: #666666;
}

/***************************
            Portfolio
****************************/
.portfolio-filter {
    text-align: center;
    margin-bottom: 50px;
}

.portfolio-filter li {
    border-radius: 30px;
    padding: 5px 25px;
    cursor: pointer;
}

.portfolio-filter li.select-cat {
    color: #fff;
}

.isotope_items{
    width: 100%;
}

.single_item {
    position: relative;
    margin: 0;
    padding: 0;
    text-align: center;
    margin-bottom: 30px;
}

.isotope_items.single_item.portfolio-big-img {
    width: 450px;
}

.isotope_items.single_item.portfolio-small-img {
    width: 250px;
}

.single_item_content {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: .9;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    text-align: center;
    vertical-align: middle;
    color: #fff;
    padding: 35% 0;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
}

.single_item_content a {
    color: #fff;
    border: 1px solid #fff;
    border-radius: 50%;
    padding: 7px 10px;
    margin-bottom: 15px;
    display: inline-block
}

.single_item_content span {
    display: block;
    font-size: 24px;
}

.single_item_content h6 {
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 10px;
}

.single_item:hover .single_item_content {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.social-share {
    background: #fff;
    border-radius: 6px;
    padding: 30px;
    -webkit-box-shadow: 0px 0px 56px 14px rgba(186, 179, 226, 0.08);
    box-shadow: 0px 0px 56px 14px rgba(186, 179, 226, 0.08);
    margin-bottom: 50px;
}

.social-share ul {
    margin-bottom: 0;
}

.social-share ul li a {
    display: inline-block;
    padding: 10px 35px;
    border-radius: 50px;
    color: #fff;
}

.social-share ul li a i {
    margin-right: 10px;
}

.social-share ul li:nth-child(1) a {
    background: #900C3F;
}

.social-share ul li:nth-child(2) a {
    background: #900C3F;
}

.social-share ul li:nth-child(3) a {
    background: #900C3F;
}

.social-share ul li:nth-child(4) a {
    background: #900C3F;
}

.post-navigation {
    border: 1px solid #f2f2f2;
    padding: 40px 40px 30px;
    border-radius: 6px;
}

.post-navigation h5 {
    font-size: 16px;
    margin-top: 5px;
    line-height: 26px;
}

.post-navigation:hover {
    color: #fff;
}

.post-navigation:hover h5 {
    color: #fff;
}

.portfolio-sec .prevPortfolio {
    cursor: pointer;
    color: #fff;
    padding: 20px;
    border-radius: 76% 24% 24% 76% / 50% 50% 50% 50%;
}

.portfolio-sec .nextPortfolio {
    cursor: pointer;
    color: #fff;
    padding: 20px;
    border-radius: 24% 76% 76% 24% / 50% 50% 50% 50%;
}

.portfolio-sec span {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 10px;
    display: block;
}

.portfolio-sec h2 {
    font-size: 35px;
    color: #900C3F;
    font-weight: bold;
}

.portfolio-sec p {
    margin-bottom: 43px;
}

.portfolio-sec ul li:first-child {
    margin-right: 20px;
}

.portfolio-item {
    -webkit-box-shadow: 0px 0px 56px 14px rgba(186, 179, 226, 0.08);
    box-shadow: 0px 0px 56px 14px rgba(186, 179, 226, 0.08);
    border-radius: 6px;
    margin-bottom: 20px;
}

.portfolio-item h5 {
    color: #260634;
    font-weight: 600;
    padding: 30px;
    margin-bottom: 0;
}

/***************************
    Keyword checker
****************************/
.seo-form {
    background: #fff;
    margin-bottom: -130px;
    margin-top: 130px;
    -webkit-box-shadow: 0px 30px 56px 14px rgba(186, 179, 226, 0.08);
    box-shadow: 0px 30px 56px 14px rgba(186, 179, 226, 0.08);
    position: relative;
    z-index: 1;
}

.seo-form:before {
    position: absolute;
    content: '';
    left: -40%;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: url("https://demo1.aecglobal.co.in/Content/zutech/leaf.png");
    background-repeat: no-repeat;
}

.seo-form h3 {
    background: #900C3F;
    color: #fff;
    text-align: center;
    padding: 35px;
}

.seo-form .seo-form-content {
    padding: 35px;
    padding-bottom: 10px;
}

.seo-form .seo-form-content label {
    margin-top: -17px;
    position: absolute;
    margin-left: 30px;
    background: #fff;
    padding: 0 5px;
}

.seo-form input {
    width: 100%;
    height: 60px;
    padding: 0 30px;
    margin-bottom: 25px;
    border: none;
    background: #fcfbff;
}

.seo-form input[type=submit] {
    color: #fff;
    text-transform: uppercase;
}


/***************************
    Call To Action
****************************/
.call-to-action-bg {
    background: url("https://demo1.aecglobal.co.in/Content/zutech/call-to-action.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
}

.call-to-action {
    color: #fff;
    padding: 55px 0 65px;
}

.call-to-action h1 {
    margin-bottom: 25px;
}

.call-to-action a {
    color: #fff;
    padding: 10px 20px;
    display: inline-block;
    border-radius: 3px;
    margin-top: 15px;
}

.footer-call-to-action {
    overflow: hidden;
    padding: 80px;
    background: #fff;
    -webkit-box-shadow: 0px 0px 56px 14px rgba(186, 179, 226, 0.08);
    box-shadow: 0px 0px 56px 14px rgba(186, 179, 226, 0.08);
    margin: 160px auto;
}

.footer-call-to-action h2 {
    font-size: 35px;
    color: #900C3F;
    font-weight: bold;
}

.footer-call-to-action span {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
}

.footer-call-to-action a {
    display: inline-block;
    color: #fff;
    padding: 15px 35px;
    border-radius: 5px;
}

/***************************
    Testimonial
****************************/

   

/***************************
            Widget
****************************/
.widget {
    padding: 30px;
    background: #fff;
    -webkit-box-shadow: 0px 0px 56px 14px rgba(186, 179, 226, 0.08);
    box-shadow: 0px 0px 56px 14px rgba(186, 179, 226, 0.08);
    margin-bottom: 30px;
    border-radius: 6px;
}

.widget:last-child {
    margin-bottom: 0;
}

.widget-title {
    color: #900C3F;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 30px;
}

.widget.widget_recent_post img {
    float: left;
    margin-right: 15px;
    border-radius: 5px;
}


.widget.widget_recent_post ul li a {
    line-height: 20px;
    overflow: hidden;
    display: inline-block;
    color: #341242;
}

.widget > ul {
    margin: 0;
    padding: 0;
}

.widget ul li {
    list-style: none;
    border-bottom: 1px solid #f2f2f2;
    margin-bottom: 15px;
    padding-bottom: 15px;
    color: #900C3F;
}

.widget ul li:last-child {
    margin-bottom: 0;
    border-bottom: none;
}

.widget ul li a {
    color: #666666;
}

.widget ul li span {
    float: right;
}

.widget.widget_recent_post span {
    color: #900C3F;
    font-size: 14px;
    float: left;
}

.widget.widget_tags a {
    display: inline-block;
    padding: 2px 15px;
    border: 1px solid #7f7f7f;
    border-radius: 50px;
    margin-bottom: 15px;
    margin-right: 10px;
    color: #900C3F;

}

.widget.widget_tags a:hover {
    color: #fff;
    border: 1px solid #fff;
}

/***************************
            Footer
****************************/
.contact-form input[type='email'],
.contact-form input[type='text'],
.contact-form input[type='number'] {
    height: 50px;
    margin-bottom: 30px;
    color: black;
}

.contact-form textarea {
    height: 150px;
    margin-bottom: 30px;
    color: #900C3F;
}

.contact-form .zutech-btn input {
    border: none;
}

/***************************
            pricing
****************************/
.pricing .section-title span {
    text-transform: uppercase;
    font-weight: bold;
}

.pricing .section-title h1 {
    color: #260634;
}

.pricing .section-title p {
    color: #666666;
}

.pricing-item .pricing-card {
    color: #fff;
    padding: 35px 50px 60px;
    text-align: center;
    border-radius: 6px;
    position: relative;
    width: 47%;
    float: left;
    margin: 0 7.5px;
}

.pricing-item .pricing-card h1 {
    font-weight: bold
}

.pricing-item .pricing-card h1 span {
    font-size: 14px;
    font-weight: 400;
}

/***************************
        Backtotop
****************************/

#backtotop {
    position: fixed;
    bottom: 40px;
    right: 30px;
    display: none;
}

#backtotop i {
    color: #900c3f;
    border: 2px solid yellow;
padding-right: 10px;
padding-top: 5px;
padding-bottom: 5px;
background: yellow;
    padding-left: 10px;
    border-radius: 20px;
}

#backtotop i:hover {
    cursor: pointer;
}

/***************************
            Footer
****************************/
.site-footer {
    margin-top: 130px;
    padding-top: 190px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.site-footer ul {
    padding: 50px 0 40px;
}

.site-footer ul li {
    margin: 0 25px
}

.site-footer ul li a {
    color: black;
}

.site-footer .footer-copyright {
    text-align: center;
    background: #900C3F;
    color: #fff;
    padding: 25px 0 10px;
}

.site-footer-2 {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
}

.footer-widget {
    color: #fff;
}

.footer-logo {
    margin-bottom: 20px;
}

.footer-widget ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.footer-widget ul li {
    line-height: 20px;
}

.footer-widget ul li i {
    margin-right: 10px;
}

.footer-widget ul li a {
    color: #fff;
}


.footer-widget .widget-title {
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 30px;
}

.footer-widget.widget-newslatter input[type=text] {
    width: 100%;
    height: 50px;
    background: transparent;
    text-align: center;
    background: #900C3F;
    border: none;
    margin-bottom: 20px;
    color: #fff;
}
.site-footer-2 {
    background: url(./images/footer-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
}
.footer-widget.widget-newslatter input[type=submit] {
    width: 100%;
    height: 50px;
    background: transparent;
    text-align: center;
    color: #fff;
    background: #900C3F;
    border: none;
    cursor: pointer;
}

.site-footer-2 .footer-copyright {
    text-align: center;
    color: #fff;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    margin-top: 25px;
    padding-top: 20px
}

@media (max-width: 1366px) {
    .banner .bnr-counter, .space_1 {
        display: none;
    }
    .bnr-shape-img-3 img{
         width: 85%;
         float: right;
    }
    .banner-2 .bnr-social {
        margin-top: 75px;
    }
    .banner-shape-animate img:nth-child(1) {
        right: 11%;
        top: 47%;
        width: 41%;
    }
    .banner-shape-animate img:nth-child(2) {
        right: 1%;
        top: 58%;
    }
    .banner-shape-animate img:nth-child(3) {
        right: 4%;
        top: 47%;
        z-index: 5;
    }
    .banner-shape-animate img:nth-child(4) {
        right: 8%;
        top: 39%;
        z-index: 4;
    }
    .banner-shape-animate img:nth-child(5) {
        right: 12%;
        top: 31%;
        z-index: 3;
    }
    .banner-shape-animate img:nth-child(6) {
        right: 16%;
        top: 24%;
    }
    .banner-shape-animate img:nth-child(7) {
        right: 25%;
        top: 17%;
        width: 25%;
    }
    .banner-4 .banner4-image {
        right: 0%;
        position: absolute;
        top: 16%;
    }
    .banner-3-content {
        padding-top: 230px;
    }
    .banner4-image .img-fluid {
        width: 90%;
        right: -65px;
        position: relative;
    }
    .banner-4 {
        width: 100%;
        color: #fff;
        background-position: 24% -62%;
        height: 900px;
        position: relative;
    }
    
    
    
    
    
}
@media (max-width: 1024px) {
    #responsive-navbar-nav{
        margin-left: 0!important;
    }
    .pricingTable .title {
        color: #333;
        font-size: 34px!important;
        font-weight: 300;
        text-transform: capitalize;
        letter-spacing: 1px;
        margin: 0;
    }
    .banner .bnr-shape-img-2 {
        width: 55%;
        height: 92%;
    }
    .banner h1 {
        font-size: 40px!important;
        color: #900c3f;
        text-shadow: 1px 2px #272926;
        margin-bottom: 20px;
        font-weight: 700;
    }
    .navbar-nav a {
        font-size: 13px!important;
        font-weight: 700!important;
        padding: 6px!important;
    }
    .banner {
        height: auto !important;
    }

    .pic-right-bottom {
        display: none;
    }

    .seo-form:before {
        display: none;
    }

    .banner-2 .bnr-shape-img-3 {
        right: 0;
        width: 60%;
    }

    .banner .bnr-shape-img {
        right: 0;
        width: 60%;
    }

    .banner-2 {
        height: 700px;
    }

    .animate-obj {
        display: none;
    }

    .counter-sec .progress-item {
        width: 125px;
    }
}

@media (max-width: 768px) {
 
   .banner h1 {
        font-size: 48px;
    }

    .banner .bnr-counter {
        margin-top: 40px;
    }

    .banner .bnr-counter ul li:after {
        display: none;
    }

    .banner .bnr-counter ul li:nth-child(2) {
        margin: 0;
    }

    .isotope_items.single_item.portfolio-big-img {
        width: 100%;
    }

    .isotope_items.single_item.portfolio-small-img {
        width: 100%;
    }

    .awards {
        margin-right: 30px;
        margin-top: 50px;
    }

    .testi-img {
        float: none;
        text-align: center;
        margin-right: 0;
    }

    .slick-slide img {
        display: inline-block;
        width: 100%;
    }

    .testi-content {
        padding: 30px;
    }

    .pricing .section-title {
        text-align: center;
        margin-bottom: 50px;
    }

    .blog-item-shape {
        margin-bottom: 50px;
    }

    .footer-call-to-action a {
        padding: 10px 18px;
    }

    .footer-call-to-action {
        padding: 40px;
    }

    .footer-call-to-action h2 {
        font-size: 30px;
    }

    .footer-widget {
        margin-bottom: 50px;
    }

    .banner-2 .bnr-social {
        margin-top: 40px;
    }

    .counter-bg {
        padding: 70px 0 310px;
    }

    .counter-item:after {
        display: none;
    }

    .service-item {
        margin-bottom: 30px;
    }

    .partner img {
        margin-bottom: 50px;
    }
    .photo-anim img {
        width: 100%;
    }
}

@media (max-width: 414px) {
    .d-xs-none {
        display: none;
    }

    


    .bnr-shape-img-2 {
        display: none;
    }

    .banner {
        padding: 100px 0 0;
        text-align: center;
    }

    .banner-3 {
        height: 540px;
    }

    .banner-3 h1 {
        margin-top: 20px;
        font-size: 32px;
        line-height: 40px;
    }

    .banner-3-content {
        padding-top: 150px;
        text-align: center;
    }

    .banner h1 {
        font-size: 32px;
    }

    .section-title {
        text-align: center;
    }

    .feature-item {
        text-align: center;
        margin-bottom: 30px;
    }

    .feature-item-icon {
        float: none;
        margin: 0 auto 30px;
    }

    .pricing-item .pricing-card {
        width: 100%;
        margin: 0;
    }

    .pricing-item .pricing-features {
        width: 100%;
        margin: 0;
        padding-left: 0;
        margin-top: 40px;
    }

    .footer-call-to-action {
        margin: 50px 0;
    }

    .footer-call-to-action h2 {
        margin-bottom: 30px;
    }

    .bnr-shape-img-3,
    .bnr-shape-img {
        display: none;
    }

    .banner-2 {
        height: 570px;
    }

    .banner-2 {
        text-align: center;
    }

    .banner-2 .bnr-btn ul li a {
        margin-right: 0;
    }

    .counter-item p {
        margin-bottom: 40px;
    }

    .service-item-2 {
        text-align: center;
    }

    .service-bg {
        padding: 40px 15px;
    }

    .breadcrumb-bnr {
        height: 300px;
    }

    .breadcrumb-content {
        padding: 30% 0;
    }

    .breadcrumb-bnr img {
        display: none;
    }

    .breadcrumb-content h1 {
        font-size: 32px;
    }

    .excerpt-content,
    .content-content {
        padding: 30px 15px 15px;
    }

    .excerpt-item h3,
    .content-item h3 {
        font-size: 18px;
        margin-bottom: 0;
    }

    .excerpt-item ul li,
    .content-item ul li {
        margin-bottom: 0;
    }

    .widget_recent_post {
        margin-top: 100px;
    }

    .social-share ul li a {
        padding: 9px 25px;
    }

    .relative-post img {
        width: 100%;
    }
}



.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -25px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '←';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: -25px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '→';
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}



W
.no-scroll-y {
	overflow-y: hidden;
}

/* Preloader */
.ctn-preloader {
	align-items: center;
  cursor: none;
	display: flex;
  height: 100%;
  justify-content: center;
	position: fixed;
	left: 0;
  top: 0;
	width: 100%;
  z-index: 900;
}

.ctn-preloader .animation-preloader {
	position: absolute;
  z-index: 100;
}

/* Spinner cargando */
.ctn-preloader .animation-preloader .spinner {
  animation: spinner 1s infinite linear;
	border-radius: 50%;
  border: 3px solid rgba(0, 0, 0, 0.2);
  border-top-color: #900C3F;
  height: 9em;
  margin: 0 auto 3.5em auto;
  width: 9em;
}

/* Texto cargando */
.ctn-preloader .animation-preloader .txt-loading {
  font: bold 5em 'Montserrat', sans-serif;
	text-align: center;
	user-select: none;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:before {
  animation: letters-loading 4s infinite;
    color: #212529;
    
  content: attr(data-text-preloader);
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transform: rotateY(-90deg);
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading {
    color: #900C3F;
	position: relative;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(2):before {
  animation-delay: 0.2s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(3):before {
  animation-delay: 0.4s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(4):before {
  animation-delay: 0.6s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(5):before {
  animation-delay: 0.8s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(6):before {
  animation-delay: 1s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(7):before {
  animation-delay: 1.2s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(8):before {
  animation-delay: 1.4s;
}

.ctn-preloader .loader-section {
  background-color: #ffffff;
  height: 100%;
	position: fixed;
  top: 0;
  width: calc(50% + 1px);
}

.ctn-preloader .loader-section.section-left {
  left: 0;
}

.ctn-preloader .loader-section.section-right {
  right: 0;
}

.loaded .animation-preloader {
  opacity: 0;
  transition: 0.3s ease-out;
}

.loaded .loader-section.section-left {
  transform: translateX(-101%);
  transition: 0.7s 0.3s all cubic-bezier(0.1, 0.1, 0.1, 1.000);
}

.loaded .loader-section.section-right {
  transform: translateX(101%);
  transition: 0.7s 0.3s all cubic-bezier(0.1, 0.1, 0.1, 1.000);
}

/* Animación del preloader */
@keyframes spinner {
	to {
		transform: rotateZ(360deg);
	}
}

/* Animación de las letras cargando del preloader */
@keyframes letters-loading {
  0%,
  75%,
  100% {
  	opacity: 0;
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

/* Tamaño de portatil hacia atras (portatil, tablet, celular) */
@media screen and (max-width: 767px) {
	/* Preloader */
	/* Spinner cargando */	
	.ctn-preloader .animation-preloader .spinner {
		height: 8em;
		width: 8em;
	}

	/* Texto cargando */
	.ctn-preloader .animation-preloader .txt-loading {
	  font: bold 3.5em 'Montserrat', sans-serif;
	}
}

@media screen and (max-width: 500px) {
	/* Prelaoder */
	/* Spinner cargando */
	.ctn-preloader .animation-preloader .spinner {
		height: 7em;
		width: 7em;
	}

	/* Texto cargando */
	.ctn-preloader .animation-preloader .txt-loading {
	  font: bold 2em 'Montserrat', sans-serif;
	}
}











* {box-sizing:border-box}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Hide the images by default */
.mySlides {
  display: none;
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

     /* testinomial start */
         .carousel {
    margin: 0 auto;
    padding: 0 70px;
}
.carousel .item {
    color: #999;
    overflow: hidden;
    min-height: 120px;
    font-size: 17px;
}
.carousel .media {
    position: relative;
    padding: 0 0 0 20px;
}
.carousel .media img {
    width: 75px;
    height: 75px;
    display: block;
    border-radius: 50%;
}
.carousel .testimonial-wrapper {
    padding: 0 10px;
}
.carousel .testimonial {
    color: #808080;
    position: relative;
    padding: 15px;
    background: #f1f1f1;
    border: 1px solid #efefef;
    border-radius: 3px;
    margin-bottom: 15px;
    min-height: 155px; 
}
.carousel .testimonial::after {
    content: "";
    width: 15px;
    height: 15px;
    display: block;
    background: #f1f1f1;
    border: 1px solid #efefef;
    border-width: 0 0 1px 1px;
    position: absolute;
    bottom: -8px;
    left: 46px;
    transform: rotateZ(-46deg);
}
.carousel .star-rating li {
    padding: 0 2px;
}
.carousel .star-rating i {
    font-size: 18px;
    color: #ffdc12;
}
.carousel .overview {
    padding: 3px 0 0 15px;
}
.carousel .overview .details {
    padding: 5px 0 8px;
}
.carousel .overview b {
    text-transform: uppercase;
    color: #ac1a27;
}
.carousel .carousel-indicators {
    bottom: -90px;
}
.carousel-indicators li, .carousel-indicators li.active {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin: 1px 2px;
}
.carousel-indicators li {   
    background: #e2e2e2;
    border: 4px solid #fff;
}
.carousel-indicators li.active {
    color: #fff;
    background:#ac1a27;
    border: 5px double;    
}



 /* testinomial end */
    .underline:hover{
   
  border-bottom: solid 1px #900c3f;

    }


    /*pricing section*/
    .pricingTable{
    font-family: 'Poppins', sans-serif;
    text-align: center;
    padding: 0 0 30px 30px;
    position: relative;
    z-index: 1;
}
.pricingTable:after{
    content: "";
    background: #900c3f;
    width: 50%;
    height: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;  
}
.pricingTable .pricing-content{
    background-color: #fff;
    padding: 15px 0 20px;
    box-shadow: 0 0 20px rgba(0,0,0,0.3);
}
.pricingTable .pricingTable-header{
    padding: 20px 10px;
    margin: 0 0 5px;
}
.pricingTable .title{
    color: #333;
    font-size: 40px;
    font-weight: 300;
    text-transform: capitalize;
    letter-spacing: 1px;
    margin: 0;
}
.pricingTable .content-list{
    padding: 0;
    margin: 0 0 27px;
    list-style: none;
    display: inline-block;
}
.pricingTable .content-list li{
    color: #333;
    font-size: 16px;
    text-transform: capitalize;
    text-align: left;
    padding: 0 0 0 35px;
    margin: 0 0 27px;
    position: relative;
}
.pricingTable .content-list li:last-child{ margin-bottom: 0; }

.pricingTable .content-list li.disable:before{
    content: "\f00d";
    color: #FF3631;
}
.pricingTable .price-value{ margin: 0 0 20px; }
.pricingTable .price-value .amount{
    font-size: 50px;
    font-weight: 300;
    line-height: 40px;
    margin: 0 0 5px;
    display: block;
}
.pricingTable .price-value .duration{
    font-size: 16px;
    display: block;
}
.pricingTable .pricingTable-signup a{
    color: #fff;
    background-color: #900c3f;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 5px 25px;
    display: inline-block;
    overflow: hidden;
    position: relative;
    z-index: 1;
    transition: all 0.3s;
}
.pricingTable .pricingTable-signup a:hover{ text-shadow: 0 0 8px rgba(0, 0, 0, 0.8); }
.pricingTable .pricingTable-signup a:before,
.pricingTable .pricingTable-signup a:after{
    content: '';
    background-color: rgba(0,0,0,0.15);
    height: 100%;
    width: 50%;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -2;
    transition: all 0.3s ease;
}
.pricingTable .pricingTable-signup a:after{
    left: auto;
    right: 0;
}
.pricingTable .pricingTable-signup a:hover:before{
    opacity: 1;
    top: 50%;
}
.pricingTable .pricingTable-signup a:hover:after{
    opacity: 1;
    top: -50%;
}
.pricingTable.blue:after,
.pricingTable.blue .pricingTable-signup a{
    background-color: #000000d1;
}
.pricingTable.magenta:after,
.pricingTable.magenta .pricingTable-signup a{
    background-color: #000000d1;
}
@media only screen and (max-width: 990px){
    .pricingTable{ margin-bottom: 50px; }
}
.breadcrumb-content{
        padding-top: 207px;
}
.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: #0000008c!important;
    border: 1px solid transparent;
    border-radius: .25rem;
}

.feature-item-content p{
overflow:hidden!important ;
}
@media (min-width: 1200px){
.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1250px!important;
}

.isotope_items{
    margin-left: 144px!important;
}
p{
    font-size: 20px!important;
    font-weight: 600!important;
}
.content-list{
    font-size: 20px!important;
    font-weight: 500!important;
}
.testimonial{
    font-size: 20px!important;
    font-weight: 500!important;
}
.service-item h5 {
    
    font-size: 28px!important;
    font-weight: 600;
}
.footer-widget li{
    font-size: 23px!important;
}
.footer-widget p{
    font-size: 23px!important;
}
h5{
    font-size: 28px!important;
    font-weight: 600;
}
.list-inline-item{
    font-size: 20px!important;
    font-weight: 500!important;
}
}

@media (min-width: 1900px){
   
   
    .navbar-nav{
        margin-left: 0!important;
    }
    .navbar-nav a {
        color: #666!important;
        font-size: 20px;
        font-weight: 600;
        padding: 8px;
    }
    #responsive-navbar-nav {
        margin-left: 44px!important;
    }
    }
    @media (min-width: 2000px){
   
   
        .navbar-nav{
            margin-left: 0!important;
        }
        .navbar-nav a {
            color: #666!important;
            font-size: 20px;
            font-weight: 600;
            padding: 8px;
        }
        #responsive-navbar-nav {
            margin-left: 64px!important;
        }
    }

  .navbar {
      padding: 0px;
  }
  
  @media (min-width: 1700px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1459px!important;
    }}
    .navbar {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 0!important; 
        margin-top: 10px;
    }

#responsive-navbar-nav
    {
        margin-left: 44px;
    }