.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.navbar-link{
color: #666!important;
}

.navbar-nav a{
  color: #666!important;
  font-size: 16px;
    font-weight: 600;
    padding: 9.5px;


}
.navbar-nav a:hover{
  border-bottom: solid 1px #900c3f;
}

navbar-l:hover{
  border-bottom: solid 1px #900c3f!important;
}
.side{
  text-align: left;

}
.left-social-icon{
  text-align: left!important;

}
#RouterNavLink{
  padding-right: .5rem;
    padding-left: .5rem;
}/* Outer */
.popup {
  width:100%;
  height:100%;
  display:none;
  position:absolute;
  top:0px;
  z-index: 1069;
  left:0px;
}

/* Inner */
.popup-inner {
  max-width:700px;
  width:90%;
  padding:40px;
  position:absolute;
  top:50%;
  left:50%;
  -webkit-transform:translate(-50%, -50%);
  transform:translate(-50%, -50%);
  box-shadow:0px 2px 16px rgb(0 0 0 / 16%);
  border-radius:3px;
  background:#f1f1f1;
}

/* Close Button */
.popup-close {
  width:30px;
  height:30px;
  padding-top:4px;
  display:inline-block;
  position:absolute;
  top:0px;
  right:0px;
  transition:ease 0.25s all;
  -webkit-transform:translate(50%, -50%);
  transform:translate(50%, -50%);
  border-radius:1000px;
  background:rgba(0,0,0,0.8);
  font-family:Arial, Sans-Serif;
  font-size:20px;
  text-align:center;
  line-height:100%;
  color:#fff;
}

.popup-close:hover {
  -webkit-transform:translate(50%, -50%) rotate(180deg);
  transform:translate(50%, -50%) rotate(180deg);
  background:rgba(0,0,0,1);
  text-decoration:none;
}



.popup-scroll{
max-height: 100%;
padding:0 1em 0 0;
}
.popup-scroll::-webkit-scrollbar {background-color:#EEE;width:10px;}
.popup-scroll::-webkit-scrollbar-thumb {
border:1px #EEE solid;border-radius:2px;background:#777;
-webkit-box-shadow: 0 0 8px #555 inset;box-shadow: 0 0 8px #555 inset;
-webkit-transition: all .3s ease-out;transition: all .3s ease-out;
}
.popup-scroll::-webkit-scrollbar-track {-webkit-box-shadow: 0 0 2px #ccc;box-shadow: 0 0 2px #ccc;}	


.head{
  
  color: #900c3f;
  font-weight: bold;
  font-size: 37px;
  text-align: center;
  padding: 0 0 31px;

}
.mb0{
  margin-bottom: 0!important;
}

.popub{
  text-align: center;
    margin-top: 21px;
    padding: 0;
}
.msg-error {
  color: #c65848;
}
.g-recaptcha.error {
  border: solid 2px #c64848;
  padding: .2em;
  width: 19em;
}
.dropdown-item{
  color: balck!Important;
}
#responsive-navbar-nav{
  justify-content: end;
}

.dropdown-menu {
  background:#900c3ff0!important;
}
.dropdown-item{
  color:white!important
}
#white{
  color: white!important;
}
.dropdown-item:focus, .dropdown-item:hover {
  color: white;
  text-decoration: none;
  background-color: #900c3ff0!important;
  border-bottom: 1px solid white!Important;
}
.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
  border-radius: 8px;
}

.marb0{
  margin-bottom: 0!important;
}
.rd-navbar-aside{
  background-color: #900C3F;
}